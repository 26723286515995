// If you want to override variables do it here
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import "./style/variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

@import "./style/layout";
@import "./style/example";

// If you want to add custom CSS you can put it here.
@import "./style/custom";

body{
  font-family: 'Poppins', sans-serif;
}
