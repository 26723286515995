// Here you can add other styles
.ldf_logo {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 3.5px;
}
.form-group{
  position: relative;
  svg{
    position: absolute;
    top: 9px;
    right: 18px;
  }
}
.login_btn {
  text-align: center;

  .btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.btn-secondary,.btn-danger,.btn-success{
  color: white;
  &:hover{
    color: white;
  }
}
.datepicker {
  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
    background-color: var(--cui-input-bg, #fff);
    background-clip: padding-box;
    border: 1px solid var(--cui-input-border-color, #b1b7c1);
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .date_icon {
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.salesOrder {
  max-width: 1000px;
  width: 100%;
  margin: 0px auto;
  background: white;
  padding: 40px;

}

.delete_popup {
  .warning_icon {
    text-align: center;
    padding-bottom: 20px;

    svg {
      width: 50px;
      cursor: pointer;
    }
  }

  p {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }

  .button {
    padding-top: 20px;
    text-align: center;

    .btn {
      max-width: 130px;
      width: 100%;
      margin: 5px 5px;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  background: #fff;
  width: 100%;
  white-space: nowrap;
  p{
    margin: 0;
  }
  .action {
    .btn {
      padding: 3px 10px;
      font-size: 14px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.shorting_icon {
  width: 20px;
}


.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px;
  border-radius: 4px;

  >li {
    display: inline;

    >a,
    >span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #4D8AAE;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #DFD4D8;
      cursor: pointer;
    }

    &:first-child {

      >a,
      >span {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {

      >a,
      >span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  >li>a,
  >li>span {

    &:hover,
    &:focus {
      color: #4D8AAE;
      background-color: #EEECED;
      border-color: #DFD4D8;
      cursor: pointer;

    }
  }

  >.active>a,
  >.active>span {

    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: #fff;
      cursor: default;
      background-color: #4D8AAE;
      border-color: #4D8AAE;
      cursor: pointer;

    }
  }

  >.disabled {

    >span,
    >span:hover,
    >span:focus,
    >a,
    >a:hover,
    >a:focus {
      color: #EEECED;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #DFD4D8;
    }
  }
}

.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}


.previewSales{
  .modal-content{
    max-height: 800px;
    overflow: auto;
  }
  
  .pdf_btn{
    background: #e7e7e778;
    max-width: 150px;
    width: 100%;
    padding: 4px 5px;
    border: 1px solid #e7e7e7;
    text-align: center;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    float: right;
    cursor: pointer;
    svg{
      width: 23px;
      margin-right: 8px;
    }
  }
  .modal-dialog{
    max-width: 700px;
  }
  .pdf_format{
    padding:20px 10px;
    overflow: auto;
    table{
      font-size: 14px;
      border-collapse: collapse;
    }
    .bill_detail{
      td{
        padding: 0px;
        line-height: 20px;
      }
      p{
        text-align: center;
        padding-bottom: 12px;
        color: #000;
        margin-bottom: 0;
      }
      .sales_logo{
        img{
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
        }
      }
    } 
    .date_info {
      td{
        border: 1px #eee solid;
         padding: 0px 8px; 
         line-height: 20px;
      }
      span{
        color: #808080;
      }
    }
    .billAddress{
      td{
        max-height: 100px;
      }
    }
    .items{
      thead{
        td{
          border-left: 0.1mm solid #e7e7e7;
          border-right: 0.1mm solid #e7e7e7;
          border-bottom: 0.1mm solid #e7e7e7;
          text-align: left;
          background-color: #e7e7e7;
        }
      }
      tbody{
        td{
          border-left: 0.1mm solid #e7e7e7;
          border-right: 0.1mm solid #e7e7e7;
          border-bottom: 0.1mm solid #e7e7e7;
          padding: 0px 7px;
          line-height: 20px;
          word-break: break-all;
        }
      }
    }

  }
}
.text-align-center{text-align: center;}


.shorting_icon{
  cursor: pointer;
}

.rdw-editor-wrapper .rdw-editor-main {
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  padding: 10px;
  height: 200px;
  background: #fff;
}

.form-control:disabled, .form-control[readonly]{
  background-color: #d8dbe063;
  border-color: #b1b7c196;
}
.search_go_btn{
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.shipTo {
  span {
    p {
      margin: 0;
    }
  }
}

.customEditor_textarea {
  .DraftEditor-root {
      .DraftEditor-editorContainer {
          padding: 0 5px;
          min-height: 50px;
          .public-DraftEditor-content {
              div {
                  //div {
                  //    .public-DraftStyleDefault-block {
                  //        .rdw-link-decorator-wrapper {
                  //            display: none;
                  //        }
                  //    }
                  //}
                  figure {
                      .rdw-image-alignment {
                          .rdw-image-imagewrapper {
                              img {
                                  object-fit: cover;
                                  width: 100%;
                                  height: auto;
                                  aspect-ratio: 16/9;
                              }
                          }
                      }
                  }
              }
              .public-DraftStyleDefault-block {
                  margin: unset;
              }
          }
      }
  }
}
.customEditor_toolbar_billToAdd {
     display: none !important;
}
.customEditor_textarea_billToAdd{
  background-color: rgba(216,219,224,.388) !important;
  border-color: rgba(177,183,193,.588) !important;
  .DraftEditor-root {
    .DraftEditor-editorContainer {
        padding: 0 5px;
        min-height: 50px;
        .public-DraftEditor-content {
            div {
                //div {
                //    .public-DraftStyleDefault-block {
                //        .rdw-link-decorator-wrapper {
                //            display: none;
                //        }
                //    }
                //}
                figure {
                    .rdw-image-alignment {
                        .rdw-image-imagewrapper {
                            img {
                                object-fit: cover;
                                width: 100%;
                                height: auto;
                                aspect-ratio: 16/9;
                            }
                        }
                    }
                }
            }
            .public-DraftStyleDefault-block {
                margin: unset;
            }
        }
    }
}
}
.billToAddress{
  background-color: #f0f1f3;
    min-height: calc(1.5em + 0.75rem + 2px );
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid var(--cui-input-border-color, #b1b7c1);
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 158px;
    overflow: auto;
    p{
      margin-bottom: 0;
    }
}